import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BottomCta from '../../components/bottom-cta'

// Load Page Content
import contentEn from '../../../content/pages/products/jasgrid.en.yaml'
import contentId from '../../../content/pages/products/jasgrid.id.yaml'
import OtherProducts from '../../components/other-products'
import SEO from '../../components/seo'

export const query = graphql`
  query JasgridQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    logo: file(relativePath: { eq: "images/jasgrid/jasgrid-logo.svg" }) {
      id
      publicURL
    }
    facilityManagementNeticketingFigure: file(
      relativePath: { eq: "images/jasgrid/jasgrid-figure-facility-management-and-e-ticketing.svg" }
    ) {
      id
      publicURL
    }
    kpiAndTeamManagementFigure: file(
      relativePath: { eq: "images/jasgrid/jasgrid-figure-kpi-and-team-management.svg" }
    ) {
      id
      publicURL
    }
    insightsAndReportingFigure: file(relativePath: { eq: "images/jasgrid/jasgrid-figure-insights-and-reporting.svg" }) {
      id
      publicURL
    }
    blazeBroadbandIcon: file(relativePath: { eq: "images/jasgrid/jasgrid-icon-blaze-broadband.svg" }) {
      id
      publicURL
    }
    inBuildingSolutionIcon: file(relativePath: { eq: "images/jasgrid/jasgrid-icon-in-building-solution.svg" }) {
      id
      publicURL
    }
    surveillanceIcon: file(relativePath: { eq: "images/jasgrid/jasgrid-icon-surveillance.svg" }) {
      id
      publicURL
    }
    cyberSecurityIcon: file(relativePath: { eq: "images/jasgrid/jasgrid-icon-cyber-security.svg" }) {
      id
      publicURL
    }
    commandCenterIcon: file(relativePath: { eq: "images/jasgrid/jasgrid-icon-command-center.svg" }) {
      id
      publicURL
    }
    smartLightIcon: file(relativePath: { eq: "images/jasgrid/jasgrid-icon-smart-light.svg" }) {
      id
      publicURL
    }
    partnerItronLogo: file(relativePath: { eq: "images/jasgrid/jasgrid-partner-logo-itron.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 85)
      }
    }
    partnerCambiumNetworkLogo: file(relativePath: { eq: "images/jasgrid/jasgrid-partner-logo-cambium-network.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 85)
      }
    }
    partnerInvisironLogo: file(relativePath: { eq: "images/jasgrid/jasgrid-partner-logo-invisiron.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 85)
      }
    }
    partnerNodefluxLogo: file(relativePath: { eq: "images/jasgrid/jasgrid-partner-logo-nodeflux.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 85)
      }
    }
    partnerUipathLogo: file(relativePath: { eq: "images/jasgrid/jasgrid-partner-logo-uipath.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 85)
      }
    }
    partnerJasbotLogo: file(relativePath: { eq: "images/jasgrid/jasgrid-partner-logo-jasbot.png" }) {
      id
      childImageSharp {
        gatsbyImageData(quality: 85)
      }
    }
    leasingAndFinancingIcon: file(relativePath: { eq: "images/jasgrid/jasgrid-icon-leasing-and-financing.svg" }) {
      id
      publicURL
    }
    itDesignAndArchitectureIcon: file(
      relativePath: { eq: "images/jasgrid/jasgrid-icon-it-design-and-architecture.svg" }
    ) {
      id
      publicURL
    }
    integrationAndCustomizationIcon: file(
      relativePath: { eq: "images/jasgrid/jasgrid-icon-integration-and-customization.svg" }
    ) {
      id
      publicURL
    }
  }
`

interface JasgridPagePropsType {
  data: {
    logo: PublicUrlType
    facilityManagementNeticketingFigure: PublicUrlType
    kpiAndTeamManagementFigure: PublicUrlType
    insightsAndReportingFigure: PublicUrlType
    blazeBroadbandIcon: PublicUrlType
    inBuildingSolutionIcon: PublicUrlType
    surveillanceIcon: PublicUrlType
    cyberSecurityIcon: PublicUrlType
    commandCenterIcon: PublicUrlType
    smartLightIcon: PublicUrlType
    partnerItronLogo: IGatsbyImageData
    partnerCambiumNetworkLogo: IGatsbyImageData
    partnerInvisironLogo: IGatsbyImageData
    partnerNodefluxLogo: IGatsbyImageData
    partnerUipathLogo: IGatsbyImageData
    partnerJasbotLogo: IGatsbyImageData
    leasingAndFinancingIcon: PublicUrlType
    itDesignAndArchitectureIcon: PublicUrlType
    integrationAndCustomizationIcon: PublicUrlType
  }
}

const JasgridPage = (props: JasgridPagePropsType): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const {
    logo,
    facilityManagementNeticketingFigure,
    kpiAndTeamManagementFigure,
    insightsAndReportingFigure,
    blazeBroadbandIcon,
    inBuildingSolutionIcon,
    surveillanceIcon,
    cyberSecurityIcon,
    commandCenterIcon,
    smartLightIcon,
    leasingAndFinancingIcon,
    itDesignAndArchitectureIcon,
    integrationAndCustomizationIcon,
  } = props.data

  const partnerItronLogo = getImage(props.data.partnerItronLogo)
  const partnerCambiumNetworkLogo = getImage(props.data.partnerCambiumNetworkLogo)
  const partnerInvisironLogo = getImage(props.data.partnerInvisironLogo)
  const partnerNodefluxLogo = getImage(props.data.partnerNodefluxLogo)
  const partnerUipathLogo = getImage(props.data.partnerUipathLogo)
  const partnerJasbotLogo = getImage(props.data.partnerJasbotLogo)

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-section uk-background-norepeat uk-background-cover tm-jasgrid-hero-background uk-flex uk-flex-middle"
          uk-height-viewport=""
          uk-img=""
          style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-flex uk-flex-between" uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div className="uk-animation-slide-top-medium uk-text-center uk-text-left@m">
                    <img className="uk-hidden@m" width="125" alt="" uk-img={logo.publicURL} />
                    <h1 className="uk-heading-small">{content.title}</h1>
                    <p className="uk-visible@m uk-text-lead">{content.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-right uk-visible@m">
                  <img width="" height="" alt="" uk-img={logo.publicURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}

      <section className="uk-section uk-margin-top">
        <div className="uk-width-1-1">
          <div className="uk-container">
            <div uk-grid="">
              <div className="uk-width-1-2@m">
                <div className="uk-flex uk-flex-center">
                  <img src={facilityManagementNeticketingFigure.publicURL} />
                </div>
              </div>
              <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                <div>
                  <h2 className="uk-h1">{content.facility_management.title}</h2>
                  <p className="uk-text-lead">{content.facility_management.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="uk-section uk-margin-top"
        style={{
          backgroundColor: '#EEF8F3',
        }}
      >
        <div className="uk-width-1-1">
          <div className="uk-container">
            <div uk-grid="">
              <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                <div>
                  <h2 className="uk-h1">{content.kpi_and_team_management.title}</h2>
                  <p className="uk-text-lead">{content.kpi_and_team_management.description}</p>
                </div>
              </div>
              <div className="uk-width-1-2@m">
                <div className="uk-flex uk-flex-center">
                  <img src={kpiAndTeamManagementFigure.publicURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-margin-top">
        <div className="uk-width-1-1">
          <div className="uk-container">
            <div uk-grid="">
              <div className="uk-width-1-2@m">
                <div className="uk-flex uk-flex-center">
                  <img src={insightsAndReportingFigure.publicURL} />
                </div>
              </div>
              <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                <div>
                  <h2 className="uk-h1">{content.insights_and_reporting.title}</h2>
                  <p className="uk-text-lead">{content.insights_and_reporting.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section uk-section-large" style={{ backgroundColor: '#EEF8F3' }}>
        <div className="uk-width-1-1">
          <div className="uk-container">
            <div className="uk-width-xlarge uk-margin-auto uk-text-center uk-margin-large-bottom">
              <h2>{content.additional_modules.title}</h2>
              <p>{content.additional_modules.description}</p>
            </div>

            <div className="uk-child-width-1-3@m" uk-grid="">
              <div className="uk-flex">
                <img src={blazeBroadbandIcon.publicURL} width="90" alt="" />
                <div className="uk-margin-left">
                  <h3>{content.additional_modules.blaze_broadband.title}</h3>
                  <p>{content.additional_modules.blaze_broadband.description}</p>
                </div>
              </div>
              <div className="uk-flex">
                <img src={inBuildingSolutionIcon.publicURL} width="90" alt="" />
                <div className="uk-margin-left">
                  <h3>{content.additional_modules.in_building_solution.title}</h3>
                  <p>{content.additional_modules.in_building_solution.description}</p>
                </div>
              </div>
              <div className="uk-flex">
                <img src={surveillanceIcon.publicURL} width="90" alt="" />
                <div className="uk-margin-left">
                  <h3>{content.additional_modules.surveillance.title}</h3>
                  <p>{content.additional_modules.surveillance.description}</p>
                </div>
              </div>
              <div className="uk-flex">
                <img src={cyberSecurityIcon.publicURL} width="90" alt="" />
                <div className="uk-margin-left">
                  <h3>{content.additional_modules.cyber_security.title}</h3>
                  <p>{content.additional_modules.cyber_security.description}</p>
                </div>
              </div>
              <div className="uk-flex">
                <img src={commandCenterIcon.publicURL} width="90" alt="" />
                <div className="uk-margin-left">
                  <h3>{content.additional_modules.command_center.title}</h3>
                  <p>{content.additional_modules.command_center.description}</p>
                </div>
              </div>
              <div className="uk-flex">
                <img src={smartLightIcon.publicURL} width="90" alt="" />
                <div className="uk-margin-left">
                  <h3>{content.additional_modules.smart_light.title}</h3>
                  <p>{content.additional_modules.smart_light.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="marketplace" className="uk-section uk-section-large">
        <div className="uk-width-1-1">
          <div className="uk-container">
            <div className="uk-width-xlarge uk-margin-auto uk-text-center uk-margin-large-bottom">
              <h2>{content.jasgrid_marketplace.title}</h2>
              <p>{content.jasgrid_marketplace.description}</p>
            </div>
            <div
              className="uk-child-width-1-3@m uk-text-center uk-margin-auto@m"
              uk-grid=""
              style={{ maxWidth: '750px' }}
            >
              <div>
                <div className="uk-card uk-card-hover uk-card-small uk-card-body">
                  {partnerItronLogo && <GatsbyImage image={partnerItronLogo} alt="Itron Logo" />}
                  <h3 className="uk-hidden uk-text-uppercase">{content.jasgrid_marketplace.itron.title}</h3>
                  <p>{content.jasgrid_marketplace.itron.description}</p>
                </div>
              </div>
              <div>
                <div className="uk-card uk-card-hover uk-card-small uk-card-body">
                  {partnerCambiumNetworkLogo && (
                    <GatsbyImage image={partnerCambiumNetworkLogo} alt="Cambium Network Logo" />
                  )}
                  <h3 className="uk-hidden uk-text-uppercase">{content.jasgrid_marketplace.cambium_network.title}</h3>
                  <p>{content.jasgrid_marketplace.cambium_network.description}</p>
                </div>
              </div>
              <div>
                <div className="uk-card uk-card-hover uk-card-small uk-card-body">
                  {partnerInvisironLogo && <GatsbyImage image={partnerInvisironLogo} alt="Invisiron Logo" />}
                  <h3 className="uk-hidden uk-text-uppercase">{content.jasgrid_marketplace.invisiron.title}</h3>
                  <p>{content.jasgrid_marketplace.invisiron.description}</p>
                </div>
              </div>
              <div>
                <div className="uk-card uk-card-hover uk-card-small uk-card-body">
                  {partnerNodefluxLogo && <GatsbyImage image={partnerNodefluxLogo} alt="Nodeflux Logo" />}
                  <h3 className="uk-hidden uk-text-uppercase">{content.jasgrid_marketplace.nodeflux.title}</h3>
                  <p>{content.jasgrid_marketplace.nodeflux.description}</p>
                </div>
              </div>
              <div>
                <div className="uk-card uk-card-hover uk-card-small uk-card-body">
                  {partnerUipathLogo && <GatsbyImage image={partnerUipathLogo} alt="UI Path Logo" />}
                  <h3 className="uk-hidden uk-text-uppercase">{content.jasgrid_marketplace.uipath.title}</h3>
                  <p>{content.jasgrid_marketplace.uipath.description}</p>
                </div>
              </div>
              <div>
                <div className="uk-card uk-card-hover uk-card-small uk-card-body">
                  {partnerJasbotLogo && <GatsbyImage image={partnerJasbotLogo} alt="Jasbot Logo" />}
                  <h3 className="uk-hidden uk-text-uppercase">{content.jasgrid_marketplace.jasbot.title}</h3>
                  <p>{content.jasgrid_marketplace.jasbot.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="uk-section">
        <div className="uk-width-1-1">
          <div className="uk-container">
            <section className="uk-section">
              <div className="uk-width-xlarge uk-margin-auto uk-text-center uk-margin-large-bottom">
                <h2>{content.all_in_one_solution.title}</h2>
              </div>

              <div className="uk-child-width-1-3@m" uk-grid="">
                <div>
                  <img src={leasingAndFinancingIcon.publicURL} width="90" alt="" />
                  <div>
                    <h3>{content.all_in_one_solution.leasing_and_financing.title}</h3>
                    <p>{content.all_in_one_solution.leasing_and_financing.description}</p>
                  </div>
                </div>
                <div>
                  <img src={itDesignAndArchitectureIcon.publicURL} width="90" alt="" />
                  <div>
                    <h3>{content.all_in_one_solution.it_design_and_architecture.title}</h3>
                    <p>{content.all_in_one_solution.it_design_and_architecture.description}</p>
                  </div>
                </div>
                <div>
                  <img src={integrationAndCustomizationIcon.publicURL} width="90" alt="" />
                  <div>
                    <h3>{content.all_in_one_solution.integration_and_customization.title}</h3>
                    <p>{content.all_in_one_solution.integration_and_customization.description}</p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <BottomCta color="#4dbd8a" />
      <OtherProducts products={content.other_product} />
    </>
  )
}

export default JasgridPage
